<template>
  <CModal
    :title="$t('batch_qc')"
    :size="state.start ? '' : 'xl'"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow v-if="state.start">
      <CCol col="12" sm="12">
        {{ $t('device_id') }}：{{ modal.data.devid ? modal.data.devid.join(', ') : '' }}
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12" v-for="e in qcItems" v-bind:key="'options_' + e.item">
        <CInputCheckbox :label="e.name" :checked.sync="qcItemsOptions[e.item]" :inline="true" />
      </CCol>
    </CRow>
    <CRow v-if="!state.start">
      <CCol col="12" md="6" xl="4" v-for="devid in modal.data.devid" v-bind:key="devid">
        <CCard>
          <CCardHeader>
            {{ $t('device_id') }}：{{ devid }}
          </CCardHeader>
          <CCardBody class="overflow-auto" :ref="devid" style="height: 250px;">
            <p v-for="(msg, index) in wsMsg[devid]" v-bind:key="devid + '_' + index">
              {{ msg }}
            </p>
            <CSpinner color="success" size="sm" v-if="wsCode[devid] === 1000" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" class="px-4" :disabled="!modal.data.devid || modal.data.devid.length === 0" @click="start()" v-if="state.start">{{ $t('start') }}</CButton>
    <CButton slot="footer" color="primary" class="px-4" :disabled="!state.next" @click="next()" v-if="!state.start && !state.end">{{ $t('next') }}</CButton>
    <CButton slot="footer" color="primary" class="px-4" @click="end()" v-if="state.end">{{ $t('end') }}</CButton>
  </CModal>
</template>

<script>
import ws from '@/utils/webSocket'

export default {
  name: 'BatchQcModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      qcItems: [],
      qcItemsOptions: {},
      state: { start: true, next: false, end: false },
      wsCode: {},
      wsMsg: {},
      wsProcid: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.state = { start: true, next: false, end: false }
        this.wsConnect()
      } else {
        this.wsClose()
      }
    }
  },
  updated: function () {
    for (const i in this.modal.data.devid) {
      const el = this.$refs[this.modal.data.devid[i]]
      if (el && el[0]) el[0].scrollTop = el[0].scrollHeight
    }
  },
  mounted: function () {
    this.getQcItems()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    wsConnect () {
      ws.newConnection()
      ws.$on('message', this.onMessage)
    },
    wsClose () {
      for (const i in this.modal.data.devid) {
        if (this.wsCode[this.modal.data.devid[i]] < 1200) {
          ws.send({ devid: this.modal.data.devid[i], options: this.qcItemsOptions, stop: 1 }, 'device.qc', this.wsProcid[this.modal.data.devid[i]])
        }
      }
      ws.$off('message')
      ws.close()
    },
    getQcItems () {
      this.$store.dispatch('getQcItems').then(res => {
        this.qcItems = res
        for (const i in res) {
          this.qcItemsOptions[res[i].item] = true
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    onMessage (json) {
      if (this.wsCode[json.data.devid] === 0 || json.code < 2000)this.wsCode[json.data.devid] = json.code
      if (json.code !== 2000) this.wsMsg[json.data.devid].push('[ ' + this.$moment().format('YYYY-MM-DD HH:mm:ss') + ' ] ' + json.message)
      if (json.procid) this.wsProcid[json.data.devid] = json.procid

      const codes = this.$_.values(this.wsCode)
      this.state.end = this.$_.every(codes, v => v >= 1200)
      this.state.next = !this.state.end && this.$_.every(codes, v => v > 1000)
    },
    start () {
      this.wsCode = {}
      this.wsMsg = {}
      for (const i in this.modal.data.devid) {
        this.$set(this.wsCode, this.modal.data.devid[i], 0)
        this.$set(this.wsMsg, this.modal.data.devid[i], [])
        ws.send({ devid: this.modal.data.devid[i], options: this.qcItemsOptions, next: 1 }, 'device.qc')
      }
      this.state.start = false
    },
    next () {
      for (const i in this.modal.data.devid) {
        if (this.wsCode[this.modal.data.devid[i]] === 1020) {
          ws.send({ devid: this.modal.data.devid[i], options: this.qcItemsOptions, next: 1 }, 'device.qc', this.wsProcid[this.modal.data.devid[i]])
        }
      }
      this.state.next = false
    },
    end () {
      this.showUpdate(false)
    }
  },
  beforeDestroy: function () {
    ws.close()
  }
}
</script>
