<template>
  <CModal
    :title="$t('batch_export_reports')"
    color="success"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('device_id') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ editData.devid ? ($_.isArray(editData.devid) ? editData.devid.join(', ') : editData.devid) : '' }}
          </div>
        </div>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('box_number')"
          :placeholder="$t('box_number')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.boxNumber"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('export') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            <CInputCheckbox
              label="Excel"
              :checked.sync="editData.export.xlsx"
              :inline="true"
            />
            <CInputCheckbox
              label="PDF"
              :checked.sync="editData.export.pdf"
              :inline="true"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="success" class="px-4" :disabled="!valid_editData_devid" @click="export_without_saving()">{{ $t('export_without_saving') }}</CButton>
    <CButton slot="footer" color="primary" class="px-4" :disabled="!valid_editData_devid" @click="updateDeviceBoxNumber()">{{ $t('save_box_number_and_export') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'BatchExportQcReportsModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      editData: { export: { xlsx: true, pdf: true } }
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) this.editData = { devid: this.modal.data.devid, boxNumber: this.modal.data.boxNumber || '', export: { xlsx: true, pdf: true } }
    }
  },
  computed: {
    valid_editData_devid () {
      return this.editData.devid && (!this.$_.isArray(this.editData.devid) || this.editData.devid.length > 0) ? true : false
    },
    valid_updateDevice () {
      return this.valid_editData_devid
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    dataUpdate (data) {
      this.$emit('dataUpdate', data)
    },
    updateDeviceBoxNumber () {
      if (this.valid_updateDevice) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateDeviceBoxNumber', this.editData).then(res => {
          this.showUpdate(false)
          this.dataUpdate(this.editData)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    export_without_saving () {
      this.showUpdate(false)
      this.dataUpdate(this.editData)
    }
  },
  beforeDestroy: function () {
  },
}
</script>
