<template>
  <CModal
    :title="$t('edit_device')"
    color="warning"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('device_id') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ editData.devid ? ($_.isArray(editData.devid) ? editData.devid.join(', ') : editData.devid) : '' }}
          </div>
        </div>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('company')"
          :placeholder="$t('company')"
          :options="companiesOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.companyId"
          :isValid="valid_editData_companyId"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('maintain_status')"
          :placeholder="$t('maintain_status')"
          :options="maintainStatusOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.maintainStatus"
          :isValid="valid_editData_maintainStatus"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :placeholder="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.enabled"
          :isValid="valid_editData_enabled"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block :disabled="!valid_editData_devid" @click="updateDevice()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditDeviceModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      companiesOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('company') + '...' }
      ],
      maintainStatusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('maintain_status') + '...' },
        { value: 0, label: this.$t('normal'), color: 'success' },
        { value: 1, label: this.$t('repair'), color: 'warning' },
        { value: 2, label: this.$t('scrap'), color: 'danger' }
      ],
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      editData: {}
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) this.editData = { devid: this.modal.data.devid, companyId: this.modal.data.companyId || null, maintainStatus: this.modal.data.maintainStatus === 0 || this.modal.data.maintainStatus === 1 || this.modal.data.maintainStatus === 2 ? this.modal.data.maintainStatus : null, enabled: this.modal.data.enabled === 0 || this.modal.data.enabled === 1 ? this.modal.data.enabled : null }
    }
  },
  computed: {
    valid_editData_devid () {
      return this.editData.devid && (!this.$_.isArray(this.editData.devid) || this.editData.devid.length > 0) ? true : false
    },
    valid_editData_companyId () {
      return this.editData.companyId ? true : false
    },
    valid_editData_maintainStatus () {
      return this.editData.maintainStatus === 0 || this.editData.maintainStatus === 1 || this.editData.maintainStatus === 2 ? true : false
    },
    valid_editData_enabled () {
      return this.editData.enabled === 0 || this.editData.enabled === 1 ? true : false
    },
    valid_updateDevice () {
      return this.valid_editData_devid && this.valid_editData_companyId && this.valid_editData_maintainStatus && this.valid_editData_enabled
    }
  },
  mounted: function () {
    this.getCompanies()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    dataUpdate (data) {
      this.$emit('dataUpdate', data)
    },
    getCompanies () {
      this.$store.dispatch('getCompanies').then(res => {
        for (const i in res) {
          this.companiesOptions.push({ value: res[i].id, label: res[i].name })
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    updateDevice () {
      if (this.valid_updateDevice) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateDevice', this.editData).then(res => {
          this.showUpdate(false)
          this.dataUpdate(this.editData)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
