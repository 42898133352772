<template>
  <CModal
    :title="$t('net_data')"
    size="lg"
    color="info"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('device_id') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ editData.devid ? ($_.isArray(editData.devid) ? editData.devid.join(', ') : editData.devid) : '' }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('carriers') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ netData.IMSI ? (/^(46601|46603|46688)/.test(netData.IMSI) ? '遠傳電信' : (/^(46697|46699|46693)/.test(netData.IMSI) ? '台灣大哥大' : (/^(46611|46692)/.test(netData.IMSI) ? '中華電信' : ''))) : '' }}
          </div>
        </div>
      </CCol>
    </CRow>
    <hr>
    <CRow v-for="(val, key, index) in netData" v-bind:key="index">
      <CCol col="12" sm="12">
        <CInput
          :label="key"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="netData[key]"
          :addInputClasses="[editData.preUpdate.includes(key) ? 'text-danger' : '']"
          plaintext
        >
          <CButton slot="append" color="danger" size="sm" title="Reload" :disabled="editData.preUpdate.includes(key) || !val" @click="editData.preUpdate.push(key)"><CIcon name="cil-reload" /></CButton>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('update_net_data')"
          :placeholder="$t('update_net_data')"
          :options="updateStartedOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.updateStarted"
          :isValid="valid_editData_updateStarted"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <hr>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        {{ $t('lock_bands') }}
      </CCol>
      <CCol col="12" sm="12">
        <CInputCheckbox
          v-for="(val, key) in lockedBandsData" v-bind:key="'bands_' + key"
          :label="key"
          :checked.sync="lockedBandsData[key]"
          inline
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="danger" :disabled="!valid_editData_devid" @click="updateNetDataLockedBands()">{{ $t('lock_bands') }}</CButton>
    <CButton slot="footer" color="primary" :disabled="!valid_editData_devid" @click="updateNetData()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'NetDataModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      updateStartedOptions: [
        { value: 1, label: this.$t('enable') },
        { value: 0, label: this.$t('disable') }
      ],
      netData: {},
      editData: { devid: '', updateStarted: null, preUpdate: [] },
      lockedBandsValidValues: ['1', '2', '3', '4', '5', '8', '12', '13', '17', '18', '19', '20', '25', '26', '28', '66'],
      lockedBandsData: {},
      editLockedBandsData: { devid: '', lockedBands: [] }
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.netData = {}
        this.editData = { devid: this.modal.data.devid, updateStarted: null, preUpdate: [] }
        this.lockedBandsData = {}
        this.editLockedBandsData = { devid: this.modal.data.devid, lockedBands: [] }
        this.getNetData()
      }
    }
  },
  computed: {
    valid_editData_devid () {
      return this.editData.devid && (!this.$_.isArray(this.editData.devid) || this.editData.devid.length > 0) ? true : false
    },
    valid_editData_preUpdate () {
      return this.editData.preUpdate && this.$_.isArray(this.editData.preUpdate) ? true : false
    },
    valid_editData_updateStarted () {
      return this.editData.updateStarted === 0 || this.editData.updateStarted === 1 ? true : false
    },
    valid_updateNetData () {
      return this.valid_editData_devid && this.valid_editData_preUpdate && this.valid_editData_updateStarted
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getNetData () {
      if (this.valid_editData_devid) {
        const loader = this.$loading.show()
        this.$store.dispatch('getNetData', { devid: this.modal.data.devid }).then(res => {
          this.netData = {
            IMSI: res[0].IMSI,
            IMEI: res[0].IMEI,
            ICCID: res[0].ICCID,
            CellID: res[0].CellID ? parseInt(res[0].CellID, 16) : null,
            eNBID: res[0].CellID ? parseInt(res[0].CellID.slice(0, res[0].CellID.length - 2), 16) : null,
            SectorID: res[0].CellID ? parseInt(res[0].CellID.slice(res[0].CellID.length - 2), 16) : null,
            LAC: res[0].LAC,
            BAND: res[0].BAND,
            RSRP: res[0].RSRP,
            RSSI: res[0].RSSI,
            CSQ: res[0].CSQ
          }
          this.editData.updateStarted = res[0].updateStarted
          this.lockedBandsData = this.$_.object(this.lockedBandsValidValues, Array(this.lockedBandsValidValues.length).fill(false))
          if (res[0].lockedBands && this.$_.isArray(res[0].lockedBands)) this.lockedBandsData = this.$_.mapObject(this.lockedBandsData, (v, k) => res[0].lockedBands.includes(k))
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      }
    },
    updateNetData () {
      if (this.valid_updateNetData) {
        const loader = this.$loading.show()
        this.editData.preUpdate = this.$_.uniq(this.editData.preUpdate)
        this.$store.dispatch('updateNetData', this.editData).then(res => {
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    updateNetDataLockedBands () {
      if (this.valid_updateNetData) {
        const loader = this.$loading.show()
        this.editLockedBandsData.lockedBands = this.$_.keys(this.$_.pick(this.lockedBandsData, v => v))
        this.$store.dispatch('updateNetDataLockedBands', this.editLockedBandsData).then(res => {
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
