<template>
  <CModal
    :title="$t('import_raw_data_file')"
    color="dark"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('upload_file') }}
          </label>
          <div class="col-sm-9">
            <img src="img/file.png" class="shadow rounded file-cursor" height="100" @click="$refs.uploadRawDataFile.click()" />
            <input type="file" multiple ref="uploadRawDataFile" style="position: absolute;clip: rect(0 0 0 0);" accept="text/plain" @change="uploadRawDataFile">
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <p class="text-muted">{{ uploadData.filesName.join(', ') }}</p>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="importRawData()" v-if="valid_importRawData">{{ $t('import') }}</CButton>
    <div slot="footer-wrapper" v-if="!valid_importRawData"></div>
  </CModal>
</template>

<style>
  .file-cursor {
    cursor: pointer;
  }
</style>

<script>
export default {
  name: 'ImportRawDataModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      uploadData: { rawArr: [], filesName: [], size: 0 }
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      this.$refs.uploadRawDataFile.value = null
      this.uploadData = { rawArr: [], filesName: [], size: 0 }
    }
  },
  computed: {
    valid_importRawData () {
      return this.uploadData.rawArr && this.uploadData.rawArr.length > 0
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    async uploadRawDataFile (e) {
      const files = e.target.files
      this.uploadData = { rawArr: [], filesName: [], size: 0 }
      if (!files || !files.length > 0) {
        //
      } else {
        for (const i in files) {
          if (!(files[i].name && files[i].type && files[i].size)) continue
          if (!/\.(txt)$/.test(files[i].name.toLowerCase()) || (files[i].type !== 'text/plain')) {
            this.$swal('Warning', '上傳檔案格式不正確，請上傳txt格式', 'warning')
          } else if ((this.uploadData.size + files[i].size) > 10 * 1024 * 1024) {
            this.$swal('Warning', '上傳檔案大小超出上限，上限10MB。(檔案大小: ' + Math.round((this.uploadData.size + files[i].size) / 1024 / 1024).toString() + 'MB)', 'warning')
            break
          } else {
            this.uploadData.size += files[i].size
            this.uploadData.filesName.push(files[i].name)
            const loader = this.$loading.show()
            const text = await this.readTextFile(files[i])
            this.uploadData.rawArr = this.uploadData.rawArr.concat(this.$_.filter(text.replace(/\s/g, '').split(','), v => v && v.length > 0))
            loader.hide()
          }
        }
      }
    },
    readTextFile (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsText(file)
        fileReader.onload = (e) => {
          try {
            resolve(e.target.result)
          } catch (e) {
            console.log(e)
            resolve('')
            // reject(e)
          }
        }
      })
    },
    importRawData () {
      if (this.valid_importRawData) {
        const loader = this.$loading.show()
        this.$store.dispatch('importRawData', this.uploadData).then(res => {
          this.$swal('Success', this.$t('uploaded_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
