<template>
  <CModal
    :title="$t('upgrade_firmware')"
    color="warning"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('device_id') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ editData.devid ? ($_.isArray(editData.devid) ? editData.devid.join(', ') : editData.devid) : '' }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="!$_.isArray(editData.devid)">
      <CCol col="12" sm="12">
        <CInput
          :label="$t('firmware_version')"
          :placeholder="$t('firmware_version')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="modal.data.firmwareVersion"
          plaintext
        />
      </CCol>
    </CRow>
    <CRow v-if="!$_.isArray(editData.devid)">
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('upgrade_status') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            <CBadge :color="$_.find(upgradeStartedOptions, option => { return option.value === modal.data.upgradeStarted }).color" v-if="modal.data.upgradeStarted !== undefined">
              {{ $_.find(upgradeStartedOptions, option => { return option.value === modal.data.upgradeStarted }).label }}
            </CBadge>
          </div>
        </div>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('target_firmware_version')"
          :placeholder="$t('target_firmware_version')"
          :options="firmwareOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.targetFirmwareVersion"
          :isValid="valid_editData_targetFirmwareVersion"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block :disabled="!valid_editData_devid" @click="upgradeDeviceFirmware()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'FirmwareModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      upgradeStartedOptions: [
        { value: -1, label: this.$t('upgrade_failed'), color: 'danger' },
        { value: 0, label: this.$t('upgraded'), color: 'success' },
        { value: 1, label: this.$t('pending_upgrade'), color: 'warning' },
        { value: 2, label: this.$t('upgrading'), color: 'info' }
      ],
      firmwareOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('firmware') + '...' }
      ],
      editData: {}
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) this.editData = { devid: this.modal.data.devid, targetFirmwareVersion: this.modal.data.targetFirmwareVersion }
    }
  },
  computed: {
    valid_editData_devid () {
      return this.editData.devid && (!this.$_.isArray(this.editData.devid) || this.editData.devid.length > 0) ? true : false
    },
    valid_editData_targetFirmwareVersion () {
      return this.editData.targetFirmwareVersion ? true : false
    },
    valid_updateDevice () {
      return this.valid_editData_devid && this.valid_editData_targetFirmwareVersion
    }
  },
  mounted: function () {
    this.getFirmwareList()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    dataUpdate (data) {
      this.$emit('dataUpdate', data)
    },
    getFirmwareList () {
      this.$store.dispatch('getFirmwareList').then(res => {
        res = res.sort((a, b) => a.version - b.version)
        for (const i in res) {
          this.firmwareOptions.push({ value: res[i].version, label: `${res[i].version} (${res[i].description})` })
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    upgradeDeviceFirmware () {
      if (this.valid_updateDevice) {
        const loader = this.$loading.show()
        this.$store.dispatch('upgradeDeviceFirmware', this.editData).then(res => {
          this.showUpdate(false)
          this.dataUpdate(this.editData)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
