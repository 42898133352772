import Vue from 'vue'
import store from '@/store'
import random from 'crypto-random-string'

const url = 'wss://parking-maintain.chainmeans.com/' + store.getters.session
let ws, reconnectTimeout
const webSocket = new Vue({
  methods: {
    newConnection () {
      try {
        ws = new WebSocket(url)
        this.init()
      } catch (e) {
        // console.log(e)
        webSocket.$emit('error', e)
      }
    },
    reConnection () {
      reconnectTimeout && clearTimeout(reconnectTimeout)
      reconnectTimeout = setTimeout(() => {
        // console.log('reconnecting')
        this.newConnection()
      }, 5000)
    },
    init () {
      ws.onopen = () => {
        // console.log('connected')
        webSocket.$emit('open', 'connected')
      }

      ws.onmessage = (message) => {
        try {
          const json = JSON.parse(message.data)
          if (json.code < 2000) {
            json.cs = 'client'
            ws.send(JSON.stringify(json))
          }
          webSocket.$emit('message', json)
          // console.log(message.data)
        } catch (e) {
          // console.log(e)
          webSocket.$emit('error', e)
        }
      }

      ws.onclose = (e) => {
        // console.log(e)
        if (e.code !== 1005) {
          this.reConnection()
        }
      }

      ws.onerror = (e) => {
        // console.log(e)
        webSocket.$emit('error', e)
      }
    },
    send (data, api, procid) {
      const json = { msgid: random({ length: 32 }), cs: 'client', api: api, data: data, procid: procid }
      if (!procid) delete json.procid
      ws.send(JSON.stringify(json))
    },
    close () {
      if (ws) ws.close()
    }
  }
})

export default webSocket
