<template>
  <CModal
    :title="$t('parsed_data')"
    size="xl"
    color="info"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="4">
        <CInput
          :label="$t('device_id')"
          :placeholder="$t('device_id')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="search.data.devid"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <date-picker
          v-model="search.data.dateRange"
          :shortcuts="false"
          :editable="false"
          :not-after="$moment()"
          range-separator="~"
          range
          width="100%"
          value-type="date"
          format="YYYY-MM-DD"
          :input-class="['form-control', 'bg-white']"
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="getParsedData()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', search.info) }}</font>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <font slot="deviceTime" slot-scope="props">
        {{ $moment(props.row.deviceTime, 'X').format('YYYY-MM-DD HH:mm:ss') }}
      </font>
      <font slot="receiveTime" slot-scope="props">
        {{ $moment(props.row.receiveTime, 'X').format('YYYY-MM-DD HH:mm:ss') }}
      </font>
      <div slot="action" slot-scope="props" class="text-center">
        <CButtonGroup>
          <CButton color="info" v-bind="{ variant: 'outline' }" @click="updateSetting(props.row)" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-settings' })">{{ $t('initialization') }}</CButton>
        </CButtonGroup>
      </div>
    </v-client-table>
    <hr v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-errorLogs' })">
    <CCard v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-errorLogs' })">
      <CCardHeader>
        {{ $t('error_logs') }}
      </CCardHeader>
      <CCardBody>
        <v-client-table :columns="errorLogsTable.columns" :data="errorLogsTable.data" :options="errorLogsTable.options">
          <font slot="errorCode" slot-scope="props">
            {{ props.row.errorCode }}
            ({{ $_.find(errorCodeArray, arr => { return arr.code === props.row.errorCode }).msg }})
          </font>
          <font slot="errorTime" slot-scope="props">
            {{ $moment(props.row.errorTime, 'X').format('YYYY-MM-DD HH:mm:ss') }}
          </font>
          <font slot="receiveTime" slot-scope="props">
            {{ $moment(props.row.receiveTime).format('YYYY-MM-DD HH:mm:ss') }}
          </font>
        </v-client-table>
      </CCardBody>
    </CCard>
    <div slot="footer-wrapper"></div>
  </CModal>
</template>

<script>
export default {
  name: 'ParsedDataModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      search: {
        data: { devid: '', startAt: this.$moment({hour: 0}).subtract(7, 'd').unix(), endAt: this.$moment().endOf('d').unix(), dateRange: [this.$moment({hour: 0}).subtract(7, 'd'), this.$moment({hour: 0})], size: 99999 },
        info: { at: '-', count: 0, sec: 0 }
      },
      table: {
        columns: [],
        data: [],
        options: {
          headings: {
            devid: this.$t('device_id'),
            remoteAddress: this.$t('remote_address'),
            pushCount: this.$t('send_count'),
            irCount: this.$t('ir_count'),
            changeCount: this.$t('change_count'),
            firmwareVersion: this.$t('firmware_version'),
            IMSI: this.$t('IMSI'),
            IMEI: this.$t('IMEI'),
            workTime: this.$t('work_time'),
            deviceTime: this.$t('device_time'),
            batteryVoltage: this.$t('battery_voltage'),
            uplaodReason: this.$t('uplaod_reason'),
            Q: this.$t('CSQ'),
            RSRP: this.$t('RSRP'),
            netTime: this.$t('net_time'),
            responseTime: this.$t('response_time'),
            X: this.$t('geomagnetic_X'),
            Y: this.$t('geomagnetic_Y'),
            Z: this.$t('geomagnetic_Z'),
            M: this.$t('geomagnetism'),
            D: this.$t('infrared'),
            CP: this.$t('car_probability'),
            FDV: this.$t('sensor_four_dimensional_vector'),
            capacitorVoltage: this.$t('capacitor_voltage'),
            chargeCount: this.$t('charge_count'),
            connectionTime: this.$t('connection_time'),
            PSMtime: this.$t('PSM_time'),
            SINR: this.$t('SINR'),
            powerConsumption: this.$t('power_consumption'),
            currentZero: this.$t('current_zero'),
            dryness: this.$t('dryness'),
            receiveTime: this.$t('receive_time'),
            action: this.$t('action')
          },
          orderBy: { ascending: false, column: 'receiveTime' },
          sortable: ['remoteAddress', 'firmwareVersion', 'IMSI', 'IMEI', 'pushCount', 'irCount', 'changeCount', 'workTime', 'batteryVoltage', 'uplaodReason', 'Q', 'RSRP', 'netTime', 'responseTime', 'X', 'Y', 'Z', 'M', 'D', 'CP', 'FDV', 'capacitorVoltage', 'chargeCount', 'connectionTime', 'PSMtime', 'SINR', 'powerConsumption', 'currentZero', 'dryness', 'deviceTime', 'receiveTime'],
          filterable: false
        }
      },
      errorLogsTable: {
        columns: ['devid', 'errorCode', 'errorTime', 'receiveTime'],
        data: [],
        options: {
          headings: {
            devid: this.$t('device_id'),
            errorCode: this.$t('error_code'),
            errorTime: this.$t('error_time'),
            receiveTime: this.$t('receive_time'),
          },
          orderBy: { ascending: false, column: 'receiveTime' },
          sortable: ['errorCode', 'errorTime', 'receiveTime'],
          filterable: false
        }
      },
      errorCodeArray: [
        { code: 0, msg: this.$t('msg0') },
        { code: 1, msg: this.$t('msg1') },
        { code: 2, msg: this.$t('msg2') },
        { code: 3, msg: this.$t('msg3') },
        { code: 4, msg: this.$t('msg4') },
        { code: 5, msg: this.$t('msg5') },
        { code: 6, msg: this.$t('msg6') },
        { code: 7, msg: this.$t('msg7') },
        { code: 20, msg: this.$t('msg20') },
        { code: 21, msg: this.$t('msg21') },
        { code: 22, msg: this.$t('msg22') },
        { code: 23, msg: this.$t('msg23') },
        { code: 24, msg: this.$t('msg24') },
        { code: 25, msg: this.$t('msg25') },
        { code: 26, msg: this.$t('msg26') },
        { code: 27, msg: this.$t('msg27') },
        { code: 28, msg: this.$t('msg28') },
        { code: 29, msg: this.$t('msg29') },
        { code: 30, msg: this.$t('msg30') },
        { code: 40, msg: this.$t('msg40') },
        { code: 103, msg: this.$t('msg103') }
      ],
      editData: { devid: '', settings: {} }
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      this.search.info = { at: '-', count: 0, sec: 0 }
      this.search.data = { devid: this.modal.data.devid, startAt: this.$moment({hour: 0}).subtract(7, 'd').unix(), endAt: this.$moment().endOf('d').unix(), dateRange: [this.$moment({hour: 0}).subtract(7, 'd'), this.$moment({hour: 0})], size: 99999 }
      this.table.data = []
      this.table.columns = this.$store.getters.root
        ? ['devid', 'remoteAddress', 'firmwareVersion', 'IMSI', 'IMEI', 'pushCount', 'irCount', 'changeCount', 'workTime', 'batteryVoltage', 'uplaodReason', 'Q', 'RSRP', 'netTime', 'responseTime', 'X', 'Y', 'Z', 'M', 'D', 'CP', 'FDV', 'capacitorVoltage', 'chargeCount', 'connectionTime', 'PSMtime', 'SINR', 'powerConsumption', 'currentZero', 'dryness', 'deviceTime', 'receiveTime']
        : ['devid', 'remoteAddress', 'firmwareVersion', 'IMSI', 'IMEI', 'pushCount', 'workTime', 'batteryVoltage', 'uplaodReason', 'Q', 'RSRP', 'netTime', 'X', 'Y', 'Z', 'D', 'CP', 'capacitorVoltage', 'chargeCount', 'PSMtime', 'SINR', 'powerConsumption', 'deviceTime', 'receiveTime']
      if (this.$_.some(this.$store.getters.roles, role => { return role === 'root' || role === 'device-settings' })) this.table.columns.push('action')
      this.errorLogsTable.data = []
      this.editData = { devid: this.modal.data.devid, settings: {} }
    }
  },
  computed: {
    valid_editData_devid () {
      return this.editData.devid && (!this.$_.isArray(this.editData.devid) || this.editData.devid.length > 0) ? true : false
    },
    valid_updateDevice () {
      return this.valid_editData_devid
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    dataUpdate (data) {
      this.$emit('dataUpdate', data)
    },
    getParsedData () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.search.data.startAt = this.$moment(this.search.data.dateRange[0]).unix()
      this.search.data.endAt = this.$moment(this.search.data.dateRange[1]).endOf('d').unix()
      this.$store.dispatch('getParsedData', this.search.data).then(res => {
        this.table.data = []
        for (const i in res) {
          this.table.data.push(JSON.parse(res[i].parsed))
        }
        this.search.info.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.search.info.count = res.length
        this.search.info.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
      if (this.$_.some(this.$store.getters.roles, role => { return role === 'root' || role === 'device-errorLogs' })) this.getErrorLogs()
    },
    getErrorLogs () {
      const loader = this.$loading.show()
      this.$store.dispatch('getErrorLogs', this.search.data).then(res => {
        this.errorLogsTable.data = res
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    updateSetting (prop) {
      if (this.valid_updateDevice) {
        const loader = this.$loading.show()
        this.editData.settings = { X0: prop.X, Y0: prop.Y, Z0: prop.Z }
        this.$store.dispatch('updateSetting', this.$_.clone(this.editData)).then(res => {
          this.showUpdate(false)
          this.dataUpdate(this.editData)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
