<template>
  <CModal
    :title="$t('at_cmd')"
    size="xl"
    color="info"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="4">
        <CInput
          :label="$t('device_id')"
          :placeholder="$t('device_id')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="search.data.devid"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <date-picker
          v-model="search.data.dateRange"
          :shortcuts="false"
          :editable="false"
          :not-after="$moment()"
          range-separator="~"
          range
          width="100%"
          value-type="date"
          format="YYYY-MM-DD"
          :input-class="['form-control', 'bg-white']"
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="getAtcmd()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', search.info) }}</font>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <font slot="createdAt" slot-scope="props">
        {{ $moment(props.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
      </font>
      <font slot="updatedAt" slot-scope="props">
        {{ $moment(props.row.updatedAt).format('YYYY-MM-DD HH:mm:ss') }}
      </font>
    </v-client-table>
    <hr>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('at_cmd')"
          :placeholder="$t('at_cmd')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="createData.cmd"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block :disabled="!valid_createData_devid" @click="createAtcmd()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'AtcmdModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      search: {
        data: { devid: '', startAt: this.$moment({hour: 0}).unix(), endAt: this.$moment().endOf('d').unix(), dateRange: [this.$moment({hour: 0}), this.$moment({hour: 0})] },
        info: { at: '-', count: 0, sec: 0 }
      },
      table: {
        columns: ['cmd', 'result', 'createdAt', 'updatedAt'],
        data: [],
        options: {
          headings: {
            cmd: this.$t('cmd'),
            result: this.$t('result'),
            createdAt: this.$t('create_time'),
            updatedAt: this.$t('update_time'),
          },
          orderBy: { ascending: false, column: 'createdAt' },
          sortable: ['createdAt'],
          filterable: false
        }
      },
      createData: { cmd: '' }
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      this.search.info = { at: '-', count: 0, sec: 0 }
      this.search.data = { devid: this.modal.data.devid, startAt: this.$moment({hour: 0}).unix(), endAt: this.$moment().endOf('d').unix(), dateRange: [this.$moment({hour: 0}), this.$moment({hour: 0})] }
      this.table.data = []
      this.createData = { devid: this.modal.data.devid, cmd: '' }
    }
  },
  computed: {
    valid_createData_devid () {
      return this.createData.devid && (!this.$_.isArray(this.createData.devid) || this.createData.devid.length > 0) ? true : false
    },
    valid_createData_cmd () {
      return /^\+[A-Z]+/.test(this.createData.cmd) ? true : false
    },
    valid_createAtcmd () {
      return this.valid_createData_devid && this.valid_createData_cmd
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getAtcmd () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.search.data.startAt = this.$moment(this.search.data.dateRange[0]).unix()
      this.search.data.endAt = this.$moment(this.search.data.dateRange[1]).endOf('d').unix()
      this.$store.dispatch('getAtcmd', this.search.data).then(res => {
        this.table.data = res
        this.search.info.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.search.info.count = res.length
        this.search.info.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    createAtcmd () {
      if (this.valid_createAtcmd) {
        const loader = this.$loading.show()
        this.$store.dispatch('createAtcmd', this.createData).then(res => {
          this.createData.cmd = ''
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
