<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="3">
              <CSelect
                placeholder="Search a company"
                :options="companiesOptions"
                :value.sync="searchData.companyId"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CSelect
                placeholder="Search a maintain status"
                :options="maintainStatusOptions"
                :value.sync="searchData.maintainStatus"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CSelect
                placeholder="Search a enable"
                :options="enabledOptions"
                :value.sync="searchData.enabled"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CInput
                :placeholder="$t('search_a') + $t('device_id')"
                v-model="searchData.devid"
                v-on:keyup.enter="getDevices()"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getDevices()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="8" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="dark" block @click="() => importRawDataModal.modal = true" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-importRawData' })">{{ $t('import_raw_data_file') }}</CButton>
            </CCol>
            <CCol col="12" sm="2">
              <CDropdown color="info" :togglerText="$t('batch_action')" placement="bottom-end" addTogglerClasses="btn-block" addMenuClasses="py-0">
                <CDropdownItem @click="() => batchQcModal = { data: { devid: $_.keys($_.pick(selected, (v, k) => v && k !== 'all')) }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-qc' })">{{ $t('batch_qc') }}</CDropdownItem>
                <CDropdownItem @click="() => batchExportQcReportsModal = { data: { devid: $_.keys($_.pick(selected, (v, k) => v && k !== 'all')) }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-qcReport' })">{{ $t('batch_export_reports') }}</CDropdownItem>
                <CDropdownItem @click="() => deviceSettingsModal = { data: { devid: $_.keys($_.pick(selected, (v, k) => v && k !== 'all')) }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-settings' })">{{ $t('batch_settings') }}</CDropdownItem>
                <CDropdownItem @click="() => autoInitializationModal = { data: { devid: $_.keys($_.pick(selected, (v, k) => v && k !== 'all')) }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-initialization' })">{{ $t('auto_initialization') }}</CDropdownItem>
                <CDropdownItem @click="() => firmwareModal = { data: { devid: $_.keys($_.pick(selected, (v, k) => v && k !== 'all')) }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-firmware' })">{{ $t('batch_upgrade_firmware') }}</CDropdownItem>
                <CDropdownItem @click="() => editDeviceModal = { data: { devid: $_.keys($_.pick(selected, (v, k) => v && k !== 'all')) }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-edit' })">{{ $t('batch_edit') }}</CDropdownItem>
              </CDropdown>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <template slot="h__selected">
              <CInputCheckbox :checked.sync="selected.all" :inline="true" @update:checked="selectAll" />
            </template>
            <template slot="selected" slot-scope="props">
              <CInputCheckbox :checked.sync="selected[props.row.devid]" :inline="true" @update:checked="selectOne()" />
            </template>
            <font slot="companyName" slot-scope="props">
              {{ props.row.companyName || $t('none') }}
            </font>
            <CBadge slot="lastStatus" slot-scope="props" :color="$_.find(lastStatusOptions, option => { return option.value === props.row.lastStatus }).color">
              {{ $_.find(lastStatusOptions, option => { return option.value === props.row.lastStatus }).label }}
            </CBadge>
            <CBadge slot="maintainStatus" slot-scope="props" :color="$_.find(maintainStatusOptions, option => { return option.value === props.row.maintainStatus }).color">
              {{ $_.find(maintainStatusOptions, option => { return option.value === props.row.maintainStatus }).label }}
            </CBadge>
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="lastStatusChangeAt" slot-scope="props">
              {{ $moment(props.row.lastStatusChangeAt).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <font slot="lastDataAt" slot-scope="props">
              {{ $moment(props.row.lastDataAt).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => parsedDataModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-parsedData' })">{{ $t('parsed_data') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => netDataModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-netData' })">{{ $t('net_data') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => atcmdModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-atcmd' })">{{ $t('at_cmd') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => deviceSettingsModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-settings' })">{{ $t('settings') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => firmwareModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-firmware' })">{{ $t('upgrade_firmware') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editDeviceModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-edit' })">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <ImportRawDataModal :data="importRawDataModal.data" :show="importRawDataModal.modal" @showUpdate="show => importRawDataModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-importRawData' })" />

      <ParsedDataModal :data="parsedDataModal.data" :show="parsedDataModal.modal" @showUpdate="show => parsedDataModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-parsedData' })" />

      <NetDataModal :data="netDataModal.data" :show="netDataModal.modal" @showUpdate="show => netDataModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-netData' })" />

      <AtcmdModal :data="atcmdModal.data" :show="atcmdModal.modal" @showUpdate="show => atcmdModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-atcmd' })" />

      <BatchQcModal :data="batchQcModal.data" :show="batchQcModal.modal" @showUpdate="show => batchQcModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-qc' })" />

      <DeviceSettingsModal :data="deviceSettingsModal.data" :show="deviceSettingsModal.modal" @showUpdate="show => deviceSettingsModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-settings' })" />

      <AutoInitializationModal :data="autoInitializationModal.data" :show="autoInitializationModal.modal" @showUpdate="show => autoInitializationModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-initialization' })" />

      <FirmwareModal :data="firmwareModal.data" :show="firmwareModal.modal" @showUpdate="show => firmwareModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-firmware' })" />

      <EditDeviceModal :data="editDeviceModal.data" :show="editDeviceModal.modal" @showUpdate="show => editDeviceModal.modal = show" @dataUpdate="data => getDevices()" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-edit' })" />

      <BatchExportQcReportsModal :data="batchExportQcReportsModal.data" :show="batchExportQcReportsModal.modal" @showUpdate="show => batchExportQcReportsModal.modal = show" @dataUpdate="exportQcReports" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-qcReport' })" />

      <ExportQcReportsModal :data="exportQcReportsModal.data" :show="exportQcReportsModal.modal" @showUpdate="show => exportQcReportsModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-qcReport' })" />

    </CCol>
  </CRow>
</template>

<script>
import ImportRawDataModal from '../device/ImportRawDataModal'
import ParsedDataModal from '../device/ParsedDataModal'
import NetDataModal from '../device/NetDataModal'
import AtcmdModal from '../device/AtcmdModal'
import BatchQcModal from '../device/BatchQcModal'
import DeviceSettingsModal from '../device/DeviceSettingsModal'
import AutoInitializationModal from '../device/AutoInitializationModal'
import FirmwareModal from '../device/FirmwareModal'
import EditDeviceModal from '../device/EditDeviceModal'
import BatchExportQcReportsModal from '../device/BatchExportQcReportsModal'
import ExportQcReportsModal from '../device/ExportQcReportsModal'

export default {
  name: 'search',
  components: {
    ImportRawDataModal,
    ParsedDataModal,
    NetDataModal,
    AtcmdModal,
    BatchQcModal,
    DeviceSettingsModal,
    AutoInitializationModal,
    FirmwareModal,
    EditDeviceModal,
    BatchExportQcReportsModal,
    ExportQcReportsModal
  },
  data () {
    return {
      columns: [ 'selected', 'companyName', 'boxNumber', 'devid', 'maintainStatus', 'enabled', 'lastStatus', 'lastStatusChangeAt', 'lastDataAt', 'action' ],
      data: [],
      options: {
        headings: {
          companyName: this.$t('company'),
          boxNumber: this.$t('box_number'),
          devid: this.$t('device_id'),
          maintainStatus: this.$t('maintain_status'),
          enabled: this.$t('enable'),
          lastStatus: this.$t('last_parking_status'),
          lastStatusChangeAt: this.$t('last_status_change_time'),
          lastDataAt: this.$t('last_data_time'),
          action: this.$t('action')
        },
        sortable: [ 'companyName', 'boxNumber', 'devid', 'maintainStatus', 'enabled', 'lastStatus', 'lastStatusChangeAt', 'lastDataAt' ],
        filterable: [ 'companyName', 'boxNumber', 'devid' ]
      },
      companiesOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('company') + '...' }
      ],
      lastStatusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('last_parking_status') + '...' },
        { value: 0, label: this.$t('car_out'), color: 'success' },
        { value: 1, label: this.$t('car_in'), color: 'danger' }
      ],
      maintainStatusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('maintain_status') + '...' },
        { value: 0, label: this.$t('normal'), color: 'success' },
        { value: 1, label: this.$t('repair'), color: 'warning' },
        { value: 2, label: this.$t('scrap'), color: 'danger' }
      ],
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { companyId: null, maintainStatus: null, enabled: null, devid: '' },
      selected: { all: false },
      importRawDataModal: { data: {}, modal: false },
      parsedDataModal: { data: {}, modal: false },
      netDataModal:  { data: {}, modal: false },
      atcmdModal:  { data: {}, modal: false },
      batchQcModal: { data: {}, modal: false },
      deviceSettingsModal: { data: {}, modal: false },
      autoInitializationModal: { data: {}, modal: false },
      firmwareModal: { data: {}, modal: false },
      editDeviceModal: { data: {}, modal: false },
      batchExportQcReportsModal: { data: {}, modal: false },
      exportQcReportsModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getCompanies()
  },
  methods: {
    getCompanies () {
      this.$store.dispatch('getCompanies').then(res => {
        if (this.$store.getters.root) {
          this.companiesOptions.push({ value: 'none', label: this.$t('none') })
        }
        for (const i in res) {
          this.companiesOptions.push({ value: res[i].id, label: res[i].name })
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    getDevices () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getDevices', this.$_.clone(this.searchData)).then(res => {
        this.selected = { all: false }
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    selectAll (checked) {
      for (const i in this.data) {
        this.selected[this.data[i].devid] = checked
      }
    },
    selectOne () {
      for (const i in this.data) {
        this.selected.all = this.selected[this.data[i].devid] || false
        if (!this.selected.all) break
      }
    },
    exportQcReports (e) {
      this.getDevices()
      this.exportQcReportsModal = { data: e, modal: true }
    }
  }
}
</script>
