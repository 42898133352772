<template>
  <div>
    <vue-html2pdf
      :show-layout="pdfOptions.showLayout"
      :float-layout="pdfOptions.floatLayout"
      :enable-download="pdfOptions.enableDownload"
      :preview-modal="pdfOptions.previewModal"
      :filename="pdfOptions.filename"
      :paginate-elements-by-height="pdfOptions.paginateElementsByHeight"
      :pdf-quality="pdfOptions.pdfQuality"
      :pdf-format="pdfOptions.pdfFormat"
      :pdf-orientation="pdfOptions.pdfOrientation"
      :pdf-content-width="pdfOptions.pdfContentWidth"
      :manual-pagination="pdfOptions.manualPagination"

      ref="pdf"
      :key="pdfKey"
    >
      <section slot="pdf-content">
        <div style="margin: 36px;font-size: 125%;" v-for="(e) in reportsData" :key="e.id">
          <CRow>
            <CCol col="6" sm="6">
              <font>正旻科技有限公司<br></font>
              <font>Chainmeans Technology Ltd.<br></font>
              <font>高雄市左營區立文路77號10樓<br></font>
              <font>10F., No. 77, Liwen Rd., Zuoying<br></font>
              <font>Dist., Kaohsiung City 813, Taiwan<br></font>
              <font>(R.O.C.)</font>
            </CCol>
            <CCol col="6" sm="6">
              <img src="img/enaoul-1024x1024.png" style="float: right;height: 126px;">
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12" sm="12" class="text-center">
              <p class="h3 mb-0">{{ $t('qc_report') }}</p>
            </CCol>
          </CRow>
          <hr class="border border-dark">
          <font>
            產品名稱：雙模複合傳感車檢器
          </font><br>
          <font>
            產品型號：
            {{ e.protocolVersion === 3 ? 'ES-T51' : '' }}
            {{ e.protocolVersion === 20 ? 'ES-T70' : '' }}
            <br>
          </font>
          <font>
            單號：
            {{ e.sn }}
          </font><br>
          <font>
            設備ID：
            {{ e.devid }}
          </font><br>
          <font>
            測試人員：
            {{ e.userName }}
          </font><br>
          <font>
            單據日期：
            {{ $moment(e.createdAt).format('YYYY-MM-DD') }}
          </font>
          <hr class="border border-dark">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('item_name') }}
                </th>
                <th scope="col">
                  {{ $t('result') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v) in $_.sortBy(e.results, 'item')" :key="v.id">
                <td>
                  {{ v.name }}
                </td>
                <td>
                  {{ $_.find(resultOptions, option => { return option.value === v.result }).label }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </vue-html2pdf>
    <excel-export :bookType="xlsxOptions.bookType" :filename="xlsxOptions.filename" :manual="xlsxOptions.manual" :sheet="xlsxOptions.sheet" ref="xlsx" />
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import {ExcelExport} from 'pikaz-excel-js'
import random from 'crypto-random-string'

export default {
  name: 'ExportQcReportsModal',
  components: {
    VueHtml2pdf,
    ExcelExport
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      pdfOptions: {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        filename: this.$t('batch_export_reports'),
        paginateElementsByHeight: 1100,
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
        manualPagination: false
      },
      pdfKey: random(({ length: 32 })),
      xlsxOptions: {
        bookType: 'xlsx',
        filename: this.$t('batch_export_reports'),
        manual: true,
        sheet: []
      },
      export: { xlsx: true, pdf: true },
      resultOptions: [
        { value: 1, label: 'Passed', color: 'success' },
        { value: 0, label: 'Failed', color: 'danger' },
        { value: -1, label: 'Untested', color: 'secondary' }
      ],
      searchData: { devid: '' },
      reportsData: []
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.pdfKey = random(({ length: 32 }))
        this.export = this.modal.data.export
        this.searchData = { devid: this.modal.data.devid }
        this.getLatestQcReport()
      }
    }
  },
  computed: {
    valid_searchData_devid () {
      return this.searchData.devid && (!this.$_.isArray(this.searchData.devid) || this.searchData.devid.length > 0) ? true : false
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getLatestQcReport () {
      if (this.valid_searchData_devid && (this.export.xlsx || this.export.pdf)) {
        const loader = this.$loading.show()
        this.reportsData = []
        this.xlsxOptions.sheet = []
        this.$store.dispatch('getLatestQcReport', this.$_.clone(this.searchData)).then(res => {
          const filename = 'qc_reports-box-' + this.$_.uniq(this.$_.pluck(res, 'boxNumber')).join(',')
          if (res.length > 0) {
            if (this.export.xlsx) {
              this.xlsxOptions.filename = filename
              const data = this.$_.groupBy(res, 'boxNumber')
              const keys = this.$_.keys(data)
              let n = 0
              let sheet = {
                tHeader: [],
                keys: [],
                sheetName: [],
                table: []
              }
              for (let [key, val] of Object.entries(data)) {
                if (n >= 3) {
                  sheet = {
                    tHeader: [],
                    keys: [],
                    sheetName: [],
                    table: []
                  }
                  n = 0
                }
                n++
                sheet.tHeader.push(this.$t('box_number'), this.$t('device_id'), 'QC ' + this.$t('single_number'))
                sheet.keys.push(`boxNumber${n}`, `devid${n}`, `sn${n}`)
                sheet.sheetName.push(key === 'null' ? 'none' : key)
                for (const i in val) {
                  if (!sheet.table[i]) sheet.table.push({})
                  sheet.table[i][`boxNumber${n}`] = key === 'null' ? '' : key
                  sheet.table[i][`devid${n}`] = val[i].devid.toString()
                  sheet.table[i][`sn${n}`] = val[i].sn
                }
                if (n === 3 || key === this.$_.last(keys)) {
                  sheet.sheetName = sheet.sheetName.join(',')
                  this.xlsxOptions.sheet.push(sheet)
                }
              }
              this.$nextTick(() => {
                this.$refs.xlsx.pikaExportExcel()
              })
            }

            if (this.export.pdf) {
              this.pdfOptions.filename = filename
              this.reportsData = res
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$refs.pdf.generatePdf()
                }, 500)
              })
            }
          }
          this.showUpdate(false)
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.showUpdate(false)
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
