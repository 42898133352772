<template>
  <CModal
    :title="$t('auto_initialization')"
    size="lg"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('device_id') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ editData.devid ? ($_.isArray(editData.devid) ? editData.devid.join(', ') : editData.devid) : '' }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('data_date_range') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            <date-picker
              v-model="editData.dateRange"
              :shortcuts="false"
              :editable="false"
              :not-after="$moment()"
              range-separator="~"
              range
              width="100%"
              value-type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white']"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('options') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            <CInputCheckbox
              :label="$t('update_to_customer_setting')"
              :checked.sync="editData.updateSettingToCustom"
              :inline="true"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="resultData">
      <CCol col="12" sm="12">
        <hr>
        <h3>{{ $t('result') }}</h3>
        <p v-if="resultData.success && resultData.success.length > 0"><b>{{ $t('successfully') }}：</b>{{ resultData.success.join(', ') }}</p>
        <p v-if="resultData.ip_fail && resultData.ip_fail.length > 0"><b>IP {{ $t('failed') }}：</b>{{ resultData.ip_fail.join(', ') }}</p>
        <p v-if="resultData.data_fail && resultData.data_fail.length > 0"><b>Data {{ $t('failed') }}：</b>{{ resultData.data_fail.join(', ') }}</p>
        <p v-if="resultData.update_fail && resultData.update_fail.length > 0"><b>Unknown {{ $t('failed') }}：</b>{{ resultData.update_fail.join(', ') }}</p>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="autoInitialization()" v-if="!resultData">{{ $t('auto_initialization') }}</CButton>
    <div slot="footer-wrapper" v-if="resultData"></div>
  </CModal>
</template>

<script>
export default {
  name: 'AutoInitializationModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      editData: {},
      resultData: null
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.editData = { devid: this.modal.data.devid, startAt: null, endAt: null, dateRange: [null, null], updateSettingToCustom: false }
        this.resultData = null
      }
    }
  },
  computed: {
    valid_editData_devid () {
      return this.editData.devid && (!this.$_.isArray(this.editData.devid) || this.editData.devid.length > 0) ? true : false
    },
    valid_autoInitialization () {
      return this.valid_editData_devid
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    autoInitialization () {
      if (this.valid_autoInitialization) {
        const loader = this.$loading.show()
        this.editData.startAt = this.editData.dateRange[0] ? this.$moment(this.editData.dateRange[0]).unix() : null
        this.editData.endAt = this.editData.dateRange[1] ? this.$moment(this.editData.dateRange[1]).endOf('d').unix() : null
        this.$store.dispatch('autoInitialization', this.editData).then(res => {
          this.resultData = res[0]
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
